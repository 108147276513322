import SparkMd5 from 'spark-md5'

export default file => {
  File.prototype.slice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice

  return new Promise((resolve, reject) => {
    // 文件切片和计算引擎准备
    const blobSlice = File.prototype.slice
    , chunkSize = 2 * 1024 * 1024
    , chunksLength = Math.ceil(file.size / chunkSize)
    , spark = new SparkMd5.ArrayBuffer()
    , fileReader = new FileReader()

    let currentChunk = 0

    // 按顺序执行切片聚合
    const loadNext = () => {
      const start = currentChunk * chunkSize
      , end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
    }

    fileReader.onload = e => {
      const slice = e.target.result

      spark.append(slice)
      currentChunk ++

      if (currentChunk < chunksLength) {
        loadNext()
      } else {
        resolve(spark.end())
      }
    }

    fileReader.onerror = e => {
      reject(e)
    }

    loadNext()
  })
}
