import axios from 'axios'
import md5 from './md5'

class OSSUploader {
  constructor() {
    this.configs = {}
    this.fileList = []
  }

  /**
   * @private
   */
  async _getConfig() {
    let response = await axios.get(`https://platform.auoktalk.com/manage/oss/policy`)
    if (response.data.code === 1) {
      this.configs = {
        dir: response.data.data.dir,
        accessKeyId: response.data.data.accessKeyId,
        policy: response.data.data.policy,
        signature: response.data.data.signature,
        host: response.data.data.host
      }
    } else {
      throw new Error(response)
    }
    return this.configs
  }
  /**
   * @private
   */

  /**
   * @public
   * @param {File}      file 要上传的文件
   * @param {Function}  complete 完成上传后的回调函数
   * @param {Function}  fail 上传失败的回调函数
   * @param {Function}  progress 上传进度回调函数
   */
  async put(file, type, complete, fail) {
    if (typeof fail === 'object') {
      fail = null
    }
    let fileMd5
    try {
      fileMd5 = await md5(file)
    } catch (E) {
      fileMd5 = `${new Date().getTime()}_${file.name}`
    }

    let fileName = fileMd5 + '_' + new Date().getTime() + '.' + file.name.split('.').pop()
    const key = `${type}/${fileName}`
    let configs = await this._getConfig(file.type, key)
    let formData = new FormData()

    formData.append('key', configs.dir + '/' + fileName)
    formData.append('OSSAccessKeyId', configs.accessKeyId)
    formData.append('policy', configs.policy)
    formData.append('signature', configs.signature)
    formData.append('file', file, fileName)
    formData.append('name', fileName)
    formData.append('success_action_status', '200')
    console.log(formData)

    // let url = configs.host;
    let url = 'https://auok.oss-cn-beijing.aliyuncs.com'
    return new Promise((resolve, reject) => {
      axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          resolve(res)
          console.log(
            'https://auok.oss-cn-beijing.aliyuncs.com/auok/' + `${key}`
          )
          complete &&
            complete(
              `https://auok.oss-cn-beijing.aliyuncs.com/` +
                configs.dir +
                '/' +
                fileName
            )
        })
        .catch(err => {
          console.log('errris iiiii:' + err)
          reject(err)
        })
    })
  }
  async put2(urlName,file, type, complete, fail) {
    if (typeof fail === 'object') {
      fail = null
    }
    let fileMd5
    try {
      fileMd5 = await md5(file)
    } catch (E) {
      fileMd5 = `${new Date().getTime()}_${file.name}`
    }
    let fileName = fileMd5 + '.' + file.name.split('.').pop()
    const key = `${type}/${fileName}`
    let configs = await this._getConfig(file.type, key)
    let formData = new FormData()

    formData.append('key', configs.dir + '/' + fileName)
    formData.append('OSSAccessKeyId', configs.accessKeyId)
    formData.append('policy', configs.policy)
    formData.append('signature', configs.signature)
    formData.append('file', file, fileName)
    formData.append('name', fileName)
    formData.append('success_action_status', '200')
    console.log(formData)

    // let url = configs.host;
    let url = `https://auok.oss-cn-beijing.aliyuncs.com`
    return new Promise((resolve, reject) => {
      axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          resolve(res)
          console.log(
            'https://auok.oss-cn-beijing.aliyuncs.com/auok/' + `${key}`
          )
          complete &&
            complete(
              `${res.data.data.fileName}?timestamp=${new Date().getTime()}`
            )
        })
        .catch(err => {
          console.log('errris iiiii:' + err)
          reject(err)
        })
    })
  }

  /**
   * @public
   * @param {File}      file 要上传的文件
   * @param {Function}  complete 完成上传后的回调函数
   * @param {Function}  fail 上传失败的回调函数
   * @param {Function}  progress 上传进度回调函数
   */
   async customPut(file, type, complete, fileName, appId) {
    // const key = `${type}/${appId}/${fileName}`
    // let configs = await this._getConfig(file.type, key)
     let configs = await this._getConfig()
    let formData = new FormData()

    formData.append('key',  `cdn/kid-mp/skin/${appId}/${fileName}`)
    formData.append('OSSAccessKeyId', configs.accessKeyId)
    formData.append('policy', configs.policy)
    formData.append('signature', configs.signature)
    formData.append('file', file, fileName)
    formData.append('name', fileName)
    formData.append('success_action_status', '200')
    console.log(formData)

    // let url = configs.host;
    let url = 'https://auok.oss-cn-beijing.aliyuncs.com'
    return new Promise((resolve, reject) => {
      axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          resolve(res)
          complete &&
            complete(`https://auok.oss-cn-beijing.aliyuncs.com/cdn/kid-mp/skin/${appId}/${fileName}`)
        })
        .catch(err => {
          console.log('errris iiiii:' + err)
          reject(err)
        })
    })
  }

  // 上传多个图片
  uploadList(fileList, appId) {
    let resultList = []
    let uploadCount = 0
    return new Promise((resolve, reject) => {
      fileList.forEach(item => {
        let split = item.name.split('.')
        let type = split[1]
        this.customPut(item.raw,appId ,(res) => {
          uploadCount++
          resultList.push(res)
          if (uploadCount === fileList.length) {
            resolve(resultList)
          }
        },  item.name, appId)
      })
    })
  }
}

export default new OSSUploader()
