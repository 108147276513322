import request from "../index";
export async function orgAdd(params) {
    const res = await request("/org/add", params, {
        method: "POST",
    });
    return res;
}

export async function orgAuthInfo(params) {
    const res = await request("/org/auth", params);
    return res;
}

export async function orgOneInfo(params) {
    const res = await request("/announcement/one", params);
    return res;
}

export async function orgAuthStatus(params) {
    const res = await request("/org/status", params);
    return res;
}

export async function orgContentSave(params) {
    const res = await request("/announcement/save", params, {
        method: "POST",
    });
    return res;
}

export async function orgContentList(params) {
    const res = await request("/announcement/list", params);
    return res;
}

export async function createMpQRcode(params) {
    const res = await request("/announcement/qrcode", params);
    return res;
}
